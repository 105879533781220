.send-invoice-btn:focus,
.send-invoice-btn:active {
  box-shadow: none !important;
}

.badge.badge-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
  padding: 5px 10px;
  display: inline-block;
}

.align-middle {
  font-weight: 600 !important;
}

.badge svg {
  height: 12px;
  width: 11px;
  font-size: 12px;
  stroke-width: 3;
  vertical-align: sub;
}

.invoice-padding {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.invoice-number-date .d-flex {
  justify-content: space-between !important;
}

.invoice-spacing {
  margin: 1.45rem 0;
}

.invoice-to-title {
  margin-bottom: 21px;
}

.invoice-product-details.border {
  padding: 3.75rem 2.2rem 2.3rem 2.2rem !important;
  border-bottom:1px solid #ebe9f1;
  border-left: none !important;
  border-right: none !important;
}

.invoice-product-details{
  padding: 3.75rem 2.2rem 2.3rem 2.2rem !important;
  border-bottom:1px solid #ebe9f1;
  border-left: none;
  border-right: none;
}

.invoice-product-details .product-details-border {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}

.invoice-total-item {
  display: flex;
  justify-content: space-between;
}

.invoice-total-wrapper {
  max-width: 12rem;
  width: 100%;
}

.invoice-total-title,
.invoice-total-amount {
  margin-bottom: 4.9px;
}
.invoice-total-amount {
  font-weight: 600;
}

.repeater-wrapper:not(:last-child) {
  margin-bottom: 22px !important;
}

.item-options-menu {
  min-width: 20rem !important;
}

.invoice-product-actions svg {
  margin-right: 0px !important;
  width: 20px !important;
  height: 20px !important;
}

.invoice-product-actions svg {
  cursor: pointer;
}

.form-control[data-test]{
  background-color: transparent !important;
}