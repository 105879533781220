.media-aside {
    margin-right: 1rem !important;
}

.b-avatar-img
{
    border-radius: inherit;
}

.b-avatar .b-avatar-img img {
    width: 90px;
    height: 90px;
    max-height: auto;
    object-fit: cover;
}

.nav-justified .nav-item, .nav-justified>.nav-link {
    flex-basis: auto !important;
    flex-grow: 0 !important;
    text-align: center;
}

.nav-tabs {
border-bottom: 0px !important;
}

.nav-tabs button {
color: black;
}

.nav-tabs .active{
  border-radius: 4px !important;
    border-color: #7367F0 !important;
    background-color: #7367F0 !important;
    color: #FFFFFF !important;
    box-shadow: 0 8px 25px -8px #7367f0 !important;
}

.nav-justified .nav-item{
    margin-right: 5px !important;
}

.card.border-primary{
    border: 1px solid #7367f0 !important;
}

.app-timeline[data-v-879a380c] {
    margin-left: 1rem !important;
}

.app-user-View ul{
    list-style-type: none !important;
    padding-left: 0px;
}
  
.timeline-item[data-v-4d50203e]:not(:last-of-type) {
    padding-bottom: 2rem;
}

 .timeline-item[data-v-4d50203e] {
    padding-left: 2rem !important;
}

li.timeline-item{
    position: relative;
}

.timeline-item .timeline-item-point[data-v-4d50203e] {
    left: -6px;
}

 .timeline-item .timeline-item-point[data-v-4d50203e] {
    border-radius: 50%;
}
 .timeline-variant-primary .timeline-item-point[data-v-4d50203e] {
    background-color: #7367f0;
}
.timeline-item .timeline-item-point[data-v-4d50203e] {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    z-index: 1;
}

.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    left: -4px;
    right: 0;
}

 .timeline-item .timeline-item-point[data-v-4d50203e]:before {
    border-radius: 50%;
}

.timeline-item[data-v-4d50203e]:not(:last-of-type) {
    border-left: 1px solid #ebe9f1;
}

.timeline-variant-primary .timeline-item-point[data-v-4d50203e]:before {
    background-color: rgba(115,103,240,.12);
}

.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    content: "";
    z-index: 1;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -4px;
    bottom: 0;
}

.timeline-item .timeline-item-point[data-v-4d50203e] {
    left: -6px;
}

.timeline-item .timeline-item-point[data-v-4d50203e] {
    border-radius: 50%;
}

.timeline-variant-warning .timeline-item-point[data-v-4d50203e] {
    background-color: #ff9f43;
}

.timeline-item .timeline-item-point[data-v-4d50203e] {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    z-index: 1;
}


.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    content: "";
    z-index: 1;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -4px;
    bottom: 0;
}
.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    left: -4px;
    right: 0;
}
.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    border-radius: 50%;
} 
.timeline-variant-warning .timeline-item-point[data-v-4d50203e]:before {
    background-color: rgba(255,159,67,.12);
}

.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    left: -4px;
    right: 0;
}

.timeline-item .timeline-item-point[data-v-4d50203e] {
    left: -6px;
}

.timeline-item .timeline-item-point[data-v-4d50203e] {
    border-radius: 50%;
}
.timeline-variant-info .timeline-item-point[data-v-4d50203e] {
    background-color: #00cfe8;
}
.timeline-item .timeline-item-point[data-v-4d50203e] {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    z-index: 1;
}

 .timeline-item .timeline-item-point[data-v-4d50203e]:before {
    border-radius: 50%;
}
 .timeline-variant-info .timeline-item-point[data-v-4d50203e]:before {
    background-color: rgba(0,207,232,.12);
}
.timeline-item .timeline-item-point[data-v-4d50203e]:before {
    content: "";
    z-index: 1;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -4px;
    bottom: 0;
}

.largeForm .modal-content{
    width: 650px !important;
  
}

/* .filter-select .css-1vbtk0w-control {
    border: none !important;
    width: 135px !important;
    text-align: end !important;
    

} */

