.card-profile{
    position: relative;
}

.card-profile .medal-img{
    position: absolute;
    right: 25px;
    top: 0px;
}



.b-avatar:hover {
    -ms-transform: scale(1.1); 
    -webkit-transform: scale(1.1); 
    transform: scale(1.1); 
  }
.card-header{
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fix svg{
    margin-right: 0px !important;
}

.card .card-title {
    /* font-weight: 500;
    font-size: 1.285rem; */
    font-weight: 500;
    line-height: 1.3334;
    font-size: 1.125rem !important;
    font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: rgba(47, 43, 61, 0.78);
}

.card .card-header {
    border-bottom: none;
    padding: 1.5rem;
}

.card-statistics .card-header {
    padding-left: 2.4rem !important;
}

.card-statistics .statistics-body {
    padding: 2rem 2.4rem!important;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
}

.card-body{
    padding: 1.5rem;
}

.card-transaction .card-body {
    padding-top: 0px;
}

.card-transaction .transaction-item:not(:last-child) {
    margin-bottom: 1.5rem;
}

.card-transaction .transaction-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.size svg{
    width: 24px;
    height: 24px;
}

.size-18 svg{
    width: 18px;
}