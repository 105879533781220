.custom_modal {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    margin: 0;
    width: 30%;
    height: 30%;
}



.viewonly:disabled {
    background-color: transparent !important;
    border: 1px solid white !important;
    color: rgb(110, 107, 123) !important;
    font-family: Montserrat, sans-serif, Helvetica, Arial, serif !important;
}

.viewonly:disabled {
    background-color: transparent !important;
    border: 1px solid white !important;
    color: rgb(110, 107, 123) !important;
    font-family: Montserrat, sans-serif, Helvetica, Arial, serif !important;
}