@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');
.memberPackage td , .spacePackage td {
  vertical-align: middle;
}

.input-group:focus-within[focused="true"] .input-group-text {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #7367f0 !important;
  
}

.meetingForm {
  height: 100%;
  display: flex;
  flex-direction: column;
}

input:invalid[focused="true"] {
  border: 1px solid #dc3545;
}

.input-group:focus-within .input-group-text {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #7367f0 !important;
}

span.error {
  font-size: 12px;
  padding: 3px;
  color: #dc3545;
  display: none;
}

span.e {
    font-size: 12px;
  padding: 3px;
  color: #dc3545;
}

input:invalid[focused="true"] ~ span {
  display: block;
  border-color: #dc3545;
}

.d-flex.sort svg{
  opacity: 0.6;
}

.d-flex.sort svg.ascending:last-child{
  color: black;
  opacity: 1;
  font-weight: bolder;
}

.d-flex.sort svg.descending:first-child{
  color: black;
  opacity: 1;
  font-weight: bolder;
}

.table-avatar{
  /* font-weight: bold;
  color: rgba(110,107,123); */
  line-height: 1.467;
  font-size: 0.9375rem;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(47, 43, 61, 0.68);
  font-weight: 500;
  text-decoration: none;
}

.table-avatar:hover{
  color: #7367f0;
}
