/* .custom-form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
    width: 80%; 
  }
   */

  .dotted-border-button {
    border: 2px dotted rgb(157, 134, 239) !important;
  }
