.custom_modal {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    margin: 0;
    width: 30%;
    height: 30%;
}

.custom-modal .modal-dialog {

    /* Adjust the height as needed */
}

.viewonly:disabled {
    background-color: transparent !important;
    border: 1px solid white !important;
    color: rgb(110, 107, 123) !important;
    font-family: Montserrat, sans-serif, Helvetica, Arial, serif !important;
}

.textarea {
    height: 100px;
    overflow: hidden;
    resize: none;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border: 1px solid #CED4DA !important;
}
.fs-7 {
  margin: 1px;
  font-size: 0.9rem;
}
.fs-8 {
  margin: 1px;
  font-size: 0.7rem;
}
.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: black !important;
}
check-input:valid {
  border: 1px solid rgba(0, 0, 0, .25) !important;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: none !important;
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border: 1px solid rgba(0, 0, 0, .25) !important;
}